import React, { createContext, useContext, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import Router, { useRouter } from 'next/router';

import { Context } from './MainContext';

import { SurveyModal } from '@/components';
import MissingPlotLocation from '@/components/organism/dashboard/MissingPlotLocation';
import {
  StageData,
  StageStatusMappingType,
} from '@/components/organism/dashboard/Stages/Stage.type';
import AppRoutes from '@/constants/AppRoutes';
import {
  PREFERRED_LANG_COOKIE,
  REMIND_LATER_SURVEY_COOKIE,
  SKIP_UPDATE_PLOT_LOCATION_COOKIE,
} from '@/constants/storage.constants';
import { getLocaleCode } from '@/helpers/locale';
import logger from '@/helpers/logger';
import { getCookie, sanitizeLog, setCookie } from '@/helpers/utils';
import Client from '@/lib/contentFul';
import {
  confirmVilla,
  deleteVilla,
  getCurrentStageApi,
  getHelpForms,
  getMe,
  getStageByName,
  getUserPendingSurvey,
  getVillaList,
  vendorRatingSubmit,
  getNotify,
  readNotify,
} from '@/services/users';
import { ContentTypeResponseType } from '@/types/ContentFul.type';
import {
  IDashboardContextProps,
  IDashboardContextReturnType,
  IDashboardContextState,
} from '@/types/dashboardContext.type';
import { selectedVilla } from '@/types/response.type';
import { IMe } from '@/types/user.type';

export const DashboardContext = createContext<IDashboardContextReturnType>(
  {} as IDashboardContextReturnType
);

const DashboardProvider = ({ children }: IDashboardContextProps) => {
  const router = useRouter();
  const { locale } = router;
  const { setContextData } = useContext(Context);

  const [state, setState] = useState<IDashboardContextState>({
    user: undefined,
    serviceStages: [],
    currentStage: undefined,
    lastStage: undefined,
    stageStatusMapping: {},
    isLoading: true,
    userLoading: true,
    helpFormLoading: true,
    helpForms: [],
    selectedVillas: [],
    selectedVillaLoading: true,
    isViewLoading: false,
    isConfirmLoading: false,
    selectedCompareVilla: [],
    compareConfirmModal: false,
    isConfirmCompareLoading: false,
    isStagesChecking: false,
    vendorRatingLoading: false,
    vendorRatingSubmitLoader: false,
    notificationDetails: [],
  });
  const [surveyUrl, setSurveyUrl] = useState<string>('');
  const [showMissingPlotLocation, setShowMissingPlotLocation] = useState(false);
  const { user } = state;

  const missingPlotLocation =
    user?.isOnboardingComplete &&
    (!user?.userInfo?.plotInfo?.id || !user?.userInfo?.plotInfo?.number);

  const isLoggedIn = (): boolean => {
    return !!state.user;
  };

  const setStageStatusMapping = (
    isCompleteStage: boolean,
    isCurrentStage: boolean
  ) => ({
    isCompleteStage: isCompleteStage || false,
    isCurrentStage: isCurrentStage,
  });

  useEffect(() => {
    const remindCookie = getCookie(REMIND_LATER_SURVEY_COOKIE);

    if (remindCookie) return;
    getUserPendingSurvey()
      .then(res => {
        if (res.url) {
          const url = res.url;
          setSurveyUrl(url);
          setCookie(REMIND_LATER_SURVEY_COOKIE, 'true');
        }
      })
      .catch(e => {
        logger.log(
          'error',
          sanitizeLog(`Survey error: ${JSON.stringify(e.message)}`)
        );
      });
  }, []);

  useEffect(() => {
    if (locale) {
      initialLoadStages();
      getNotification();
    }
  }, [locale]);

  useEffect(() => {
    if (state?.isStagesChecking) {
      getSelectedVillaDetails();
    }
  }, [state?.isStagesChecking]);

  useEffect(() => {
    updateUserContext();
    updatePrevRequests();
  }, [locale]);

  // Update the sidebar items status ( current, complete, ...)
  useEffect(() => {
    updateStagesStatus();
  }, [
    state.serviceStages,
    state.currentStage,
    user?.userInfo.projectInfo,
    locale,
  ]);

  const updateDashboardState = (state: Partial<IDashboardContextState>) => {
    setState(prevState => ({
      ...prevState,
      ...state,
    }));
  };

  const initialLoadStages = async () => {
    updateDashboardState({
      isLoading: true,
    });
    try {
      const page: ContentTypeResponseType = await Client.getEntries({
        content_type: 'sfService',
        locale: locale,
        include: 10,
        order: 'fields.stageOrder' as any,
      });
      const data: StageData[] = [];
      for (const item of page.items) {
        data.push({
          ...item.fields,
          isCompleteStage: false,
          isCurrentStage: false,
        });
      }

      updateDashboardState({
        serviceStages: data,
        isLoading: false,
        lastStage: data[data.length - 1],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateStagesStatus = () => {
    const stageStatusMapping: StageStatusMappingType = {};
    if (user?.userInfo.projectInfo.stage) {
      const findInx = state.serviceStages.findIndex(
        item => item.sfStageName === (user?.userInfo.projectInfo.stage || '')
      );
      if (findInx >= 0) {
        for (let i = 0; i < state.serviceStages.length; i++) {
          if (i < findInx) {
            stageStatusMapping[state.serviceStages[i].slug] =
              setStageStatusMapping(true, false);
          }
          if (i === findInx) {
            stageStatusMapping[state.serviceStages[i].slug] =
              setStageStatusMapping(false, true);
          }
          if (i > findInx) {
            stageStatusMapping[state.serviceStages[i].slug] =
              setStageStatusMapping(false, false);
          }
        }
      }
    }
    setState(prevState => ({ ...prevState, stageStatusMapping }));
  };

  const updateCurrentStage = async (abortController: AbortController) => {
    const currStageResponse = await getCurrentStageApi(
      locale || 'ar',
      abortController
    ).catch(() => {});

    if (currStageResponse && currStageResponse.data) {
      const currStage: StageData =
        currStageResponse.data.data.sfServiceCollection.items[0];

      if (user && user?.userInfo) {
        const tmp_user = { ...user };
        tmp_user.userInfo.projectInfo.stage = currStage.sfStageName;
        updateDashboardState({
          user: tmp_user,
        });
      }
      setState(prevState => {
        const findInx = state.serviceStages.findIndex(
          itm => itm.slug === currStage.slug
        );
        const newStages = [...prevState.serviceStages];
        // TODO: we can undo the following when stages api is updated with infothumbnail, infodescription ... other contentful data
        // newStages[findInx] = currStage;;
        newStages[findInx] = {
          ...currStage,
          infoThumbnail: prevState.serviceStages[findInx].infoThumbnail,
          infoDescription: prevState.serviceStages[findInx].infoDescription,
          homescreenContent: prevState.serviceStages[findInx].homescreenContent,
        };
        return {
          ...prevState,
          currentStage: currStage,
          serviceStages: newStages,
          isLoading: false,
        };
      });
      router.push({
        pathname: currStage.slug,
        query: { updated: 1 },
      });
    }
  };

  const updateStageBySlug = (slug: string, controller: AbortController) => {
    return new Promise(resolve => {
      const stage: StageData | undefined = state.serviceStages.find(
        item => item.slug === slug
      );
      if (stage?.sfStageName) {
        updateDashboardState({
          isLoading: true,
          currentStage: stage,
        });

        getStageByName(stage?.sfStageName || '', locale as string, controller)
          .then(response => {
            setState(prevState => {
              const findStageInx = prevState.serviceStages.findIndex(
                item => item.slug === slug
              );
              const newStages = [...prevState.serviceStages];

              // TODO: we can undo the following when stages api is updated with infothumbnail, infodescription ... other contentful data
              // newStages[findStageInx] =
              //   response.data.data.sfServiceCollection.items[0];
              newStages[findStageInx] = {
                ...response.data.data.sfServiceCollection.items[0],
                infoThumbnail: state.serviceStages[findStageInx].infoThumbnail,
                infoDescription:
                  state.serviceStages[findStageInx].infoDescription,
                homescreenContent:
                  state.serviceStages[findStageInx].homescreenContent,
              };

              return {
                ...prevState,
                serviceStages: newStages,
                currentStage: newStages[findStageInx],
                isLoading: false,
              };
            });
            resolve(true);
          })
          .catch(() => {});
      }
    });
  };

  /**
   * Fetch and store user info to context user state
   */
  const updateUserContext = async () => {
    updateDashboardState({
      userLoading: true,
    });
    try {
      const { data: meData } = (await getMe()).data;
      const preferredLanguage = getLocaleCode(
        meData?.userInfo?.preferredLanguage
      );
      setCookie(PREFERRED_LANG_COOKIE, preferredLanguage);

      // TODO: need to remove this after the middleware issue is fixed by Varcel
      // issue link: https://github.com/vercel/next.js/issues/49883
      // we do this redirection because because sometime user has diffrent locale in the url and in the cookie so we need to make sure that user has the same locale in the url and in the cookie
      if (locale !== preferredLanguage) {
        router.replace(router.asPath, undefined, { locale: preferredLanguage });
      }
      const showMissingPlotData = shouldShowMissingPlotModal(meData);
      const plotLocationSkipped = getCookie(SKIP_UPDATE_PLOT_LOCATION_COOKIE);
      if (showMissingPlotData && !plotLocationSkipped) {
        setShowMissingPlotLocation(true);
      }

      updateDashboardState({
        user: meData,
        userLoading: false,
      });

      setContextData({
        locale: preferredLanguage || 'ar',
      });

      datadogRum.setUser({
        accountId: meData.userInfo.accountId,
        name: meData.userInfo.nameEn,
        email: meData.userInfo.email,
        emiratesId: meData.userInfo.emiratesId,
      });
      getSelectedVillaDetails();
      return meData;
    } catch (err) {
      console.error(err);
      // datadogRum.clearUser();
      Router.push({ pathname: AppRoutes.Logout });
    }
  };

  const updateUserDetails = async () => {
    try {
      const { data: meData } = (await getMe()).data;
      updateDashboardState({
        user: meData,
        userLoading: false,
      });
    } catch (error) {
      console.error('Error while getting user details');
    }
  };

  const handleCloseSurvey = () => {
    setSurveyUrl('');
  };

  const onCloseMissingPlotModal = () => {
    setShowMissingPlotLocation(false);
    setCookie(SKIP_UPDATE_PLOT_LOCATION_COOKIE, 'true');
  };

  const shouldShowMissingPlotModal = (userProp?: IMe) => {
    const currentUser = userProp || user;

    return (
      currentUser?.isOnboardingComplete &&
      !currentUser?.userInfo?.plotInfo?.number
    );
  };

  const updatePrevRequests = async () => {
    updateDashboardState({ helpFormLoading: true });
    try {
      const response = await getHelpForms(locale || 'ar');
      const helpForms = response.data.data;
      setState(prevState => ({ ...prevState, helpForms: helpForms }));
    } catch (err) {
      console.error(err);
    } finally {
      updateDashboardState({ helpFormLoading: false });
    }
  };

  // /* Villa Listing function */
  const getSelectedVillaDetails = async () => {
    updateDashboardState({ userLoading: true });
    try {
      const response = await getVillaList();
      const villaDetails: selectedVilla[] = Array.isArray(response?.data?.data)
        ? response.data.data
        : [];
      updateDashboardState({
        selectedVillaLoading: false,
        selectedVillas: villaDetails,
        userLoading: false,
        isStagesChecking: false,
      });
    } catch (err) {
      console.error(err);
    } finally {
      updateDashboardState({ userLoading: false, isStagesChecking: false });
    }
  };

  /* Villa delete functionality */
  const updatedVillaDetails = async (
    villa: selectedVilla,
    isValid?: boolean
  ) => {
    updateDashboardState({ selectedVillaLoading: true });
    try {
      const payload: any = {
        id: villa?.id,
        villaType: villa?.villaType,
      };
      const response = await deleteVilla(payload);

      if (response && isValid) {
        router.replace('/dashboard');
      }
      if (response) {
        getSelectedVillaDetails();
      }
    } catch (err) {
      console.error('API Failed while delete villa', err);
    } finally {
      updateDashboardState({ selectedVillaLoading: false });
    }
  };

  /* Villa Confirm Functionality */

  const confirmVillaDetails = async (villa: selectedVilla) => {
    try {
      updateDashboardState({
        isConfirmLoading: true,
        isConfirmCompareLoading: true,
      });
      const previousSelectedVilla = state.selectedVillas?.find(
        item => item.isSelected || null
      );

      const payload: any = {
        villaId: villa?.id,
        villaType: villa?.villaType,
        previousVillaType: previousSelectedVilla?.villaType,
        previousVillaId: previousSelectedVilla?.id,
      };
      const response = await confirmVilla(payload);

      if (response?.data?.data?.success) {
        await updateUserDetails();
        await updateDashboardState({
          isConfirmLoading: false,
          isConfirmCompareLoading: false,
          compareConfirmModal: false,
        });

        return response?.data?.data?.success;
      }
    } catch (err) {
      console.error('API Failed while confirming the villa', err);
    } finally {
      updateDashboardState({
        isConfirmLoading: false,
        isConfirmCompareLoading: false,
      });
    }
  };

  /* Get Notification */
  const getNotification = async () => {
    try {
      updateDashboardState({ vendorRatingLoading: true });
      const response = await getNotify();
      if (response.status == 200) {
        const responseData = response?.data?.data;
        updateDashboardState({ notificationDetails: responseData });
        updateDashboardState({ vendorRatingLoading: false });
      } else {
        updateDashboardState({ notificationDetails: [] });
        updateDashboardState({ vendorRatingLoading: false });
      }
    } catch (error) {
      console.error(error, 'Error while getting notification');
    }
  };

  /* Mark as Read Notification */
  const markAsReadNotification = async (data: any) => {
    try {
      updateDashboardState({ vendorRatingLoading: true });
      const response = await readNotify(data?.id, data?.isRead);
      if (response.status == 200) {
        await getNotification();
        updateDashboardState({ vendorRatingLoading: false });
      } else {
        updateDashboardState({ notificationDetails: [] });
        updateDashboardState({ vendorRatingLoading: false });
      }
    } catch (error) {
      console.error(error, 'Error while marking notification as read');
    }
  };

  /* Confirm Vendor Rating */
  const submitVendorRating = async (details: any) => {
    try {
      updateDashboardState({ vendorRatingSubmitLoader: true });
      const response = await vendorRatingSubmit(details);
      if (response?.data) {
        updateDashboardState({ vendorRatingSubmitLoader: false });
        return response?.data;
      } else {
        updateDashboardState({ vendorRatingSubmitLoader: false });
        logger.log(
          'error',
          sanitizeLog(` ${JSON.stringify(response)}, API Response Failed`)
        );
      }
    } catch (err) {
      console.error(err, 'Rating Submit Api Failed');
    } finally {
      updateDashboardState({ vendorRatingSubmitLoader: false });
    }
  };

  return (
    <div data-testid="about-container-id">
      <DashboardContext.Provider
        value={{
          ...state,
          updateDashboardState,
          updateStageBySlug,
          updateCurrentStage,
          updateUserContext,
          isLoggedIn,
          updateHelpForms: updatePrevRequests,
          updatedVillaDetails,
          confirmVillaDetails,
          getSelectedVillaDetails,
          submitVendorRating,
          getNotification,
          markAsReadNotification,
        }}
      >
        {showMissingPlotLocation && (
          <MissingPlotLocation onClose={onCloseMissingPlotModal} />
        )}
        {!missingPlotLocation && surveyUrl && (
          <SurveyModal
            url={surveyUrl}
            isOpen={!!surveyUrl}
            onClose={handleCloseSurvey}
          />
        )}

        {children}
      </DashboardContext.Provider>
    </div>
  );
};

export default DashboardProvider;
