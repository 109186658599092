import { defineStyle } from '@chakra-ui/react';

import colors from '@/styles/themes/brand/colors';
import FontSizes from '@/styles/themes/brand/fontSizes';

const style = defineStyle({
  infoModalContainer: {
    gap: '30px',
    justifyContent: 'space-around',
    paddingTop: '35px',
    paddingBottom: '35px',
  },
  infoIcon: {
    color: colors.primary,
    width: '67px',
    height: '67px',
  },
  infoTitle: {
    fontSize: FontSizes.xxLarge,
    textAlign: 'center',
  },
  serviceRequestId: {
    fontSize: FontSizes.normal,
    align: 'center',
    paddingTop: '15px',
  },
  infoLink: {
    fontSize: FontSizes.normal,
  },
  infoText: {
    fontSize: FontSizes.normal,
    textAlign: 'center',
  },
  infoDoneButton: {
    minWidth: '150px',
  },
});

export default style;
