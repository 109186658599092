import { defineStyle } from '@chakra-ui/react';

import colors from '@/styles/themes/brand/colors';

const style = defineStyle({
  root: {
    gap: '24px',
  },
  text: {
    color: colors.text.dark,
    _ltr: {
      padding: '2px 0px 2px 12px',
    },
    _rtl: {
      padding: '2px 12px 2px 0px',
    },
    _hover: {
      textDecoration: 'underline',
    },
  },
  icon: {
    w: '25px',
    h: '25px',
  },
});

export default style;
