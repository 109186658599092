import React from 'react';

import { Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { SocialLinksType } from './SocailLinks.type';
import style from './socialLink.style';

import { Icon } from '@/components';
import { sanitizeUrl } from '@/helpers/utils';
import useTranslation from '@/hooks/useTranslate';

type SocialLinksProp = {
  socialLinks?: SocialLinksType;
};

const SocialLinks = ({ socialLinks }: SocialLinksProp) => {
  const { t } = useTranslation();

  return (
    <Flex sx={style.root}>
      {socialLinks?.fields.links.map((social, index) => {
        return (
          <Link
            aria-label={`${t('aria_label_social_links')} ${
              social.fields.iconName
            }`}
            key={`socials-${index}`}
            href={sanitizeUrl(social?.fields?.href)}
            target={social.fields.target}
          >
            {/* Added Email address and contact number for social link */}
            {social?.fields?.iconName && social.fields.value ? (
              <Flex>
                <Icon name={social.fields.iconName} sx={style.icon} />
                <Text sx={style.text} dir="ltr">
                  {social.fields.value}
                </Text>
              </Flex>
            ) : social?.fields?.iconName ? (
              <Icon name={social.fields.iconName} w="24px" h="24px" />
            ) : null}
          </Link>
        );
      })}
    </Flex>
  );
};

export default SocialLinks;
