import { Button, VStack } from '@chakra-ui/react';

import style from './InfoModal.style';
import InfoModalProps from './infoModal.type';

import { Icon, Link, Modal, Text } from '@/components';
import useTranslation from '@/hooks/useTranslate';

function InfoModal(props: InfoModalProps) {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    title,
    icon,
    info,
    url,
    serviceRequestId,
    isLoading,
  } = props;
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      showCloseButton={false}
      size={{ base: 'xl', lg: '6xl' }}
    >
      <VStack sx={style.infoModalContainer}>
        <Icon name={icon} sx={style.infoIcon} />
        <Text sx={style.infoTitle}>
          {title}
          {serviceRequestId && (
            <Text sx={style.serviceRequestId}>
              {`${t('portal_info_modal_sr_number')} #${serviceRequestId}`}
            </Text>
          )}
        </Text>
        {url ? (
          <Link
            isExternal={true}
            target="_blank"
            href={url || '#'}
            sx={style.infoLink}
          >
            {info}
          </Link>
        ) : (
          <Text sx={style.infoText}>{info}</Text>
        )}
        <Button
          onClick={onClose}
          isLoading={isLoading}
          sx={style.infoDoneButton}
        >
          {t('portal_done')}
        </Button>
      </VStack>
    </Modal>
  );
}

export default InfoModal;
