import { ReactNode } from 'react';

import { Button, Flex, VStack } from '@chakra-ui/react';

import style from './FormModal.style';

import { Icon, Modal, Text } from '@/components';
import useTranslation from '@/hooks/useTranslate';

interface FormModalProps {
  isOpen: boolean;
  isConfirmDisabled?: boolean;
  title?: string;
  description?: string;
  isLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
  children?: ReactNode;
  onCancel: () => void;
  onConfirm?: () => void;
  isVillaModal?: boolean;
  iconName?: any;
  confirmDesignModal?: boolean;
  icon?: boolean;
  isVendor?: boolean;
  showCloseButton?: boolean;
  isVendorRating?: boolean;
  vendorRatingLoading?: boolean;
}

function FormModal(props: FormModalProps) {
  const { t } = useTranslation();

  const {
    isOpen,
    isConfirmDisabled,
    title,
    description,
    isLoading,
    confirmText,
    cancelText,
    children,
    onCancel,
    onConfirm,
    isVillaModal,
    iconName,
    confirmDesignModal,
    icon,
    isVendor,
    showCloseButton,
    isVendorRating,
  } = props;

  return (
    <Modal
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton={isVendor ? showCloseButton : false}
      // size={'6xl'}
      closeOnOverlayClick={false}
      size={'5xl'}
      isVendorRating={isVendorRating}
    >
      <VStack sx={style.mainContainer}>
        {isVillaModal && !isVendor && (
          <Flex gap={'16px'} flexDirection={'column'}>
            <Flex flexDirection={'row'} justifyContent={'center'}>
              <Icon
                name={iconName}
                sx={iconName != 'confirmTicked' ? style.Icon : style.IconTicked}
              />
            </Flex>
            {title && !isVendor && <Text sx={style.title}>{title}</Text>}
          </Flex>
        )}
        {icon && !isVillaModal && (
          <Flex>
            <Icon name="arrows" sx={style.Icon} />
          </Flex>
        )}
        {title && isVendor && !isVillaModal ? (
          <Flex sx={{ width: '100%', gap: '10px' }}>
            <Icon name="vendorRating" sx={style.vendorIcon} />
            <Text sx={style.vendorTitle}>{title}</Text>
          </Flex>
        ) : title && !isVillaModal ? (
          <Text sx={style.title}>{title}</Text>
        ) : null}
        {description && (
          <Text sx={!isVillaModal ? style.description : style.villaDescription}>
            {description}
          </Text>
        )}
        {children}

        {(cancelText || confirmText) && !isVendor && !isVillaModal ? (
          <Flex sx={style.buttonContainer}>
            {confirmText && (
              <Button
                isLoading={isLoading}
                onClick={onConfirm}
                sx={style.button}
                isDisabled={isConfirmDisabled}
              >
                {confirmText || t('portal_confirm')}
              </Button>
            )}
            {cancelText && (
              <Button
                variant="outline"
                isDisabled={isLoading}
                onClick={onCancel}
                sx={style.cancelButton}
              >
                {cancelText || t('portal_cancel')}
              </Button>
            )}
          </Flex>
        ) : (
          <Flex
            sx={
              isVillaModal
                ? style.buttonContainer
                : style.buttonContainerIsVendor
            }
          >
            {cancelText && (
              <Button
                variant="outline"
                isDisabled={isLoading}
                onClick={onCancel}
                sx={!isVendor ? style.cancelButton : style.isVendorCancelButton}
              >
                {cancelText || t('portal_cancel')}
              </Button>
            )}
            {confirmText && !confirmDesignModal ? (
              <Button
                isLoading={isLoading}
                onClick={onConfirm}
                sx={style.button}
                isDisabled={isConfirmDisabled}
              >
                {confirmText || t('portal_confirm')}
              </Button>
            ) : (
              <Button
                isLoading={isLoading}
                onClick={onConfirm}
                sx={style.button}
                isDisabled={isConfirmDisabled}
              >
                {confirmText}
              </Button>
            )}
          </Flex>
        )}
      </VStack>
    </Modal>
  );
}

export default FormModal;
