import { defineStyle } from '@chakra-ui/react';

import colors from '@/styles/themes/brand/colors';
import FontSizes from '@/styles/themes/brand/fontSizes';
import FontWeights from '@/styles/themes/brand/fontWeights';

const style = defineStyle({
  mainContainer: {
    margin: '20px',
    gap: '24px',
    justifyContent: 'space-around',
  },
  Icon: {
    height: '66px',
    width: '60px',
  },
  IconTicked: {
    height: '68px',
    width: '68x',
  },
  vendorIcon: {
    height: '54px',
    width: { base: '63px', md: '54px' },
  },
  title: {
    fontSize: FontSizes.large,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontWeight: FontWeights.medium,
  },
  vendorTitle: {
    fontSize: '24px',
    whiteSpace: 'pre-wrap',
    fontWeight: FontWeights.medium,
    padding: { base: '0px', md: '10px' },
  },
  description: {
    fontSize: FontSizes.lMedium,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontWeight: FontWeights.medium,
  },
  villaDescription: {
    fontSize: FontSizes.normal,
    fontWeight: FontWeights.normal,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  buttonContainer: {
    justifyContent: 'center',
    flexDirection: { base: 'column', sm: 'row' },
    gap: '1.5rem',
  },
  cancelButton: {
    minW: '150px',
    border: `1px solid ${colors.text.darkBlue}`,
  },
  button: { minW: '150px' },
  buttonContainerIsVendor: {
    display: 'flex',
    justifyContent: 'flex-end', // Aligns buttons to the right
    flexDirection: { base: 'column', sm: 'row' }, // Column on mobile, row on larger screens
    gap: '1.5rem', // Spacing between buttons
    width: '100%', // Ensure container takes full width
  },
  isVendorCancelButton: {
    minW: '100px',
    border: `1px solid ${colors.text.darkBlue}`,
  },
});

export default style;
