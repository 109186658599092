type LogLevel = 'error' | 'warn' | 'info';
const LEVELS: Record<LogLevel, number> = {
  error: 0,
  warn: 1,
  info: 2,
};

class Logger {
  private _level: number;

  constructor(level: LogLevel) {
    this._level = LEVELS[level];
  }
  log(level: LogLevel, message: string, ...args: any[]): void {
    if (LEVELS[level] <= this._level) {
      const timestamp = new Date().toISOString();
      // eslint-disable-next-line no-console
      console[level](
        `${timestamp} [${level.toUpperCase()}]: ${message}`,
        args ? args : ''
      );
    }
  }

  info(message: string, ...args: any[]): void {
    this.log('info', message, args);
  }

  warn(message: string, ...args: any[]): void {
    this.log('warn', message, args);
  }

  error(message: string, ...args: any[]): void {
    this.log('error', message, args);
  }
}

const logger = new Logger('info');

export default logger;
